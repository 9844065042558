
import { Component, Mixins, Watch } from 'vue-property-decorator';
import AdminModule from '../../store/admin/Admin.store';
import { getModule } from 'vuex-module-decorators'
const adminStore = getModule(AdminModule);
import { DataOptions, DataTableHeader } from 'vuetify';
import { mdiMagnify, mdiEmailSendOutline } from '@mdi/js';
import { DebounceMixin, LocalForageMixin, VuetifyMixin, FeatureFlagMixin } from '@/mixins';
import Page from '@/views/Page.vue';
import { QueryOptions, RepositoryQuery, ContextMenuItem } from '@/../types/interfaces';
import SortIcon from '@/components/ui/SortIcon.vue';
import AdminAthleteAutocomplete from '@/components/admin/AdminAthleteAutocomplete.vue';
import VerifiedAssessmentImport from '@/components/admin/VerifiedAssessmentImport.vue';
import VerifiedAssessmentImportV3 from '@/components/admin/VerifiedAssessmentImportV3.vue';
import { PageState } from '@/models/PageState';
import { AthleteAssessmentDataModel, AthleteProfileModel } from '@/models';
import { formatDatePretty } from '@/helpers';
import { AthleteMetric } from '@/../types/enums';
import { capitalize } from '@/pipes';
import { athleteAssessmentDataApi } from '@/api/AthleteAssessmentDataApi';
import { AssessmentEntry } from 'types/interfaces/PyhsicalAssessmentDataFormValue';

const DEFAULT_OPTIONS = {
	page: 1,
	itemsPerPage: 10,
	sortBy: ['_id'],
	sortDesc: [true],
	groupBy: [],
	groupDesc: [false],
	multiSort: false,
	mustSort: false,
};

@Component({
	components: {
		AdminAthleteAutocomplete,
		Page,
		SortIcon,
		VerifiedAssessmentImport,
		VerifiedAssessmentImportV3,
	}
})
export default class AssessmentDashboard extends Mixins(DebounceMixin, FeatureFlagMixin, LocalForageMixin, VuetifyMixin){
	mdiMagnify = mdiMagnify;
	formatDatePretty = formatDatePretty;
	capitalize = capitalize;

	search: string = "";
	tableOptions: DataOptions = DEFAULT_OPTIONS;
	/** Selected athlete to filter results for */
	athlete: AthleteProfileModel | null = null;
	updateAthlete(athlete: AthleteProfileModel): void{
		this.athlete = athlete ?? null;
		this.debounceUpdateTable();
	}
	@Watch('tableOptions') optionsChanged(){
		this.debounceUpdateTable();
	}
	get UseV3Import(): boolean{
		return this.feature<string>('HIN-1630-verified-assessment-import') === "v3";
	}
	get TableSortBy(): string{
		const [value] = this.tableOptions.sortBy;
		return value;
	}
	get TableSortDesc(): boolean{
		const [value] = this.tableOptions.sortDesc;
		return value;
	}
	localForagePersistFields: Array<string | [string, any]> = [
		// ['tableOptions', DEFAULT_OPTIONS], 
		['search', ""],
	];
	headers: Array<DataTableHeader<any> & { metric?: AthleteMetric }> = [
		{
			text: 'Athlete Id',
			value: 'athleteId',
			sortable: false,
		},
		{
			text: 'Contact',
			value: '',
			sortable: false,
		},
		{
			text: 'Gender',
			value: 'gender',
			sortable: false,
		},
		{
			text: 'Age Group',
			value: 'playingLevel',
			sortable: false,
		},
		{
			text: 'Date',
			value: 'dateCreated',
			sortable: true,
		},
		{
			text: '',
			value: 'data-table-controls'
		},
	];
	get PageLoading(): boolean{
		return !this.AssessmentListReady;
	}
	get TableLoading(): boolean{
		return adminStore.organizationsLoading;
	}
	get AssessmentListReady(): boolean{
		return adminStore.verifiedAssessmentsInitialized && !adminStore.verifiedAssessmentsLoading;
	}
	get TotalAssessments(): number{
		return adminStore.totalVerifiedAssessments;
	}
	get TotalPages(): number{
		return Math.ceil(adminStore.totalVerifiedAssessments / this.tableOptions.itemsPerPage);
	}
	get AssessmentList(): AthleteAssessmentDataModel[]{
		return adminStore.verifiedAssessmentList;
	}
	mounted(): void{
		this.debounceUpdateTable();
	}
	tableUpdatePending: boolean = false;
	debounceUpdateTable(): void{
		this.tableUpdatePending = true;
		this.debounceCallback('updateTable', async () => {
			try{
				await this.updateTable();
			}catch(e){
				console.error("Failed to update table");
			}finally{
				this.tableUpdatePending = false;
			}
		}, 400);
	}
	async updateTable(): Promise<void>{
		this.persistField(this.LocalForagePersistFieldKeys);
		return await this.loadAssessments();
	}
	clearLocalForage(): void{
		this.clearPersistedFields();
	}

	pageState: PageState = new PageState('Initial');
	async loadAssessments(): Promise<void>{
		this.pageState = new PageState('Loading');
		try{
			const query: RepositoryQuery<AthleteAssessmentDataModel> = {
				search: this.search,
				fields: [],
			};
			const options: QueryOptions = { 
				page: this.tableOptions.page,
				limitPerPage: this.tableOptions.itemsPerPage,
			};
			if(this.tableOptions.sortBy.length > 0){
				options.sort = {
					fields: this.tableOptions.sortBy.map((field, index) => {
						return {
							field: field,
							desc: this.tableOptions.sortDesc[index],
						};
					}),
				};
			}
			if(this.athlete !== null){
				query.$match = {
					athleteId: this.athlete.id,
				};
			}
			await adminStore.loadVerifiedAssessmentList({ query, options });
		}catch(e){
			console.error(e);
		}finally{
			this.pageState = new PageState('Ready');
		}
	}

	menuItems(item: AthleteAssessmentDataModel): ContextMenuItem[]{
		return [
			!item.athleteId ? {
				icon: mdiEmailSendOutline,
				text: "Re-send Email",
				click: () => {
					console.log("Resent verification email");
				},
			}: undefined
		].filter(x => !!x);
	}

	async bulkImportAssessments(assessments: AssessmentEntry[]): Promise<void>{
		athleteAssessmentDataApi.bulkInsert(assessments);
	}

	async bulkImportAssessmentsV3({assessments,notify,fixDuplicates}: { assessments: AssessmentEntry[], notify: boolean, fixDuplicates: boolean }): Promise<void>{
		athleteAssessmentDataApi.bulkInsertV3({assessments,notify,fixDuplicates});
	}

}
