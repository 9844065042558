
import Page from '../Page.vue';
import { mdiMagnify, mdiEmailSendOutline } from '@mdi/js';
import { Component, Watch, Mixins } from 'vue-property-decorator';
import ConfirmationDialog from "@/components/ui/ConfirmationDialog.vue";
import SPQUploadDialog from "./SPQUploadDialog.vue";
import { DataTableHeader, DataOptions } from 'vuetify';
import { SpqDataModel, AthleteProfileModel } from '@/models';
import { getModule } from 'vuex-module-decorators';
import { LocalForageMixin, DebounceMixin, VuetifyMixin } from '@/mixins';
import { PageState } from '@/models/PageState';
import { RepositoryQuery, QueryOptions, ContextMenuItem } from 'types/interfaces';
import AdminAthleteAutocomplete from '@/components/admin/AdminAthleteAutocomplete.vue';
import AdminModule from '@/store/admin/Admin.store';
import { spqDataApi } from '@/api/SpqDataApi';
const adminStore = getModule(AdminModule)

const DEFAULT_OPTIONS = {
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	sortDesc: [true],
	groupBy: [],
	groupDesc: [false],
	multiSort: false,
	mustSort: false,
};

@Component({
	components:{
		Page,
		ConfirmationDialog,
		SPQUploadDialog,
		AdminAthleteAutocomplete
	}
})
export default class SPQUploadAdminDashboard extends Mixins(DebounceMixin, LocalForageMixin, VuetifyMixin){
	mdiMagnify = mdiMagnify;
	mdiEmailSendOutline = mdiEmailSendOutline;
	showSPQUpload: boolean = false
	tableOptions: DataOptions = DEFAULT_OPTIONS;
	athlete: AthleteProfileModel | null = null;
	updateAthlete(athlete: AthleteProfileModel): void{
		this.athlete = athlete ?? null;
		this.debounceUpdateTable();
	}
	search: string = "";
	headers: DataTableHeader<any>[] = [
		{
			text: 'Athlete Id',
			value: 'athleteId',
			sortable: false,
		},
		{
			text: 'Name',
			value: 'name',
			sortable: false,
		},
		{
			text: 'Email',
			value: 'email',
			sortable: false,
		},
		{
			text: 'AC',
			value: 'ac',
			sortable: true,
		},
		{
			text: 'CR',
			value: 'cr',
			sortable: true,
		},
		{
			text: 'IS',
			value: 'is',
			sortable: true,
		},
		{
			text: 'PA',
			value: 'pa',
			sortable: true,
		},
		{
			text: '',
			value: 'data-table-controls'
		},
	];

	localForagePersistFields: Array<string | [string, any]> = [
		// ['tableOptions', DEFAULT_OPTIONS], 
		['search', ""],
	];
	mounted(): void{
		this.debounceUpdateTable();
	}
	@Watch('tableOptions') optionsChanged(){
		this.debounceUpdateTable();
	}
	tableUpdatePending: boolean = false;
	debounceUpdateTable(): void{
		this.tableUpdatePending = true;
		this.debounceCallback('updateTable', async () => {
			try{
				await this.updateTable();
			}catch(e){
				console.error("Failed to update table");
			}finally{
				this.tableUpdatePending = false;
			}
		}, 400);
	}
	async updateTable(): Promise<void>{
		this.persistField(this.LocalForagePersistFieldKeys);
		return await this.loadSpqData();
	}
	get TableLoading(): boolean{
		return adminStore.spqDataLoading;
	}
	get TableSortBy(): string{
		const [value] = this.tableOptions.sortBy;
		return value;
	}
	get TableSortDesc(): boolean{
		const [value] = this.tableOptions.sortDesc;
		return value;
	}
	pageState: PageState = new PageState('Initial');
	async loadSpqData(): Promise<void>{
		this.pageState = new PageState('Loading');
		try{
			const query: RepositoryQuery<SpqDataModel> = {
				search: this.search,
				fields: [],
			};
			const options: QueryOptions = { 
				page: this.tableOptions.page,
				limitPerPage: this.tableOptions.itemsPerPage,
			};
			if(this.tableOptions.sortBy.length > 0){
				options.sort = {
					fields: this.tableOptions.sortBy.map((field, index) => {
						return {
							field: field,
							desc: this.tableOptions.sortDesc[index],
						};
					}),
				};
			}
			if(this.athlete !== null){
				query.$match = {
					athleteId: this.athlete.id,
				};
			}

			await adminStore.loadSpqDataList({ query, options });
			this.SPQDataList;
		}catch(e){
			console.error(e);
		}finally{
			this.pageState = new PageState('Ready');
		}
	}

	get SPQDataList(): SpqDataModel[]{
		return adminStore.spqDataList;
	}

	get TotalSpqData(): number{
		return adminStore.totalSpqData;
	}

	async uploadSPQ(form: Record<string, any>): Promise<void>{
		const spqData = new SpqDataModel();
		spqData.name = `${form.firstName} ${form.lastName}`
		spqData.email = form.email
		spqData.scoreAchievementCompetitiveness = form.AC
		spqData.scoreConfidenceResilience = form.CR
		spqData.scoreInteractionSportsmanship = form.IS
		spqData.scorePowerAggressiveness = form.PA
		await spqDataApi.insertSPQResult(spqData)
	}
	menuItems(item: SpqDataModel): ContextMenuItem[]{
		return [
			item.athleteId ? {
				icon: mdiEmailSendOutline,
				text: "Re-send Email",
				click: async () => {
					await spqDataApi.resendSPQResult(item)
				},
			}: undefined
		].filter(x => !!x);
	}
}
