
import { Component, Mixins } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { mdiMagnify, mdiChevronDown, mdiCogSync, mdiAccountSearch, mdiTrashCan, mdiCloudDownload } from '@mdi/js';
import Page from '../Page.vue';
import UsersAdminModule from '@/store/admin/UsersAdmin.store';
export const usersAdminStore = getModule(UsersAdminModule);
import { title } from '../../pipes';
import { DebounceMixin, LocalForageMixin, PollingMixin, VuetifyMixin } from '../../mixins';
import { DataOptions } from 'vuetify';
import { QueryOptions, RepositoryQuery, PaginatedResponse } from '../../../types/interfaces';
import { UserAccountModel } from '@/models/user/UserAccountModel';
import { userAccountApi } from '@/api/UserAccountApi';
import UserAccountList from '@/components/admin/UserAccountList.vue';
import { RoleName } from '@/../types/enums';

@Component({
	components: {
		Page,
		UserAccountList,
	}
})
export default class UserAdminDashboard extends Mixins(DebounceMixin, LocalForageMixin, PollingMixin, VuetifyMixin){
	title = title;
	mdiCogSync = mdiCogSync;
	mdiAccountSearch = mdiAccountSearch;
	mdiMagnify = mdiMagnify;
	mdiChevronDown = mdiChevronDown;
	mdiTrashCan = mdiTrashCan;
	mdiCloudDownload = mdiCloudDownload;
	search: string = "";

	page: number = 0;

	tableOptions: DataOptions = {
		page: 1,
		itemsPerPage: 8,
		sortBy: ['start'],
		sortDesc: [true],
		groupBy: [],
		groupDesc: [false],
		multiSort: false,
		mustSort: false,
	};
	localForagePersistFields: Array<string | [string, any]> = [
		'tableOptions',
		'search',
		'selectedStatuses',
	];

	get PageLoading(): boolean{
		return usersAdminStore.loading || this.syncLoading || this.usersLoading || this.tableUpdatePending;
	}
	get UserListReady(): boolean{
		return usersAdminStore.UserListReady;
	}
	get TotalUsers(): number{
		return usersAdminStore.totalUsers;
	}
	get UserList(): UserAccountModel[]{
		return usersAdminStore.userList;
	}

	selectedRoles: RoleName[] = [];
	roleColor(role: RoleName): string{
		if (role === RoleName.Athlete) {
			return this.getColor('baColorEventSale');
		} else if (role === RoleName.Parent) {
			return this.getColor('baColorEventCompleted');
		} else if (role === RoleName.Coach) {
			return this.getColor('baColorVibrantBlue');
		}
		return this.getColor('baColorEventDraft');
	}
	get RoleSelection(): {text: string, value: string}[]{
		return [
			RoleName.Athlete,
			RoleName.Parent,
			RoleName.Coach,
		].map(s => ({
			text: title(s),
			value: s,
		}));
	}

	mounted(): void{
		this.debounceUpdateTable();
	}
	tableUpdatePending: boolean = false;
	debounceUpdateTable(): void{
		this.tableUpdatePending = true;
		this.debounceCallback('updateTable', async () => {
			try{
				await this.updateTable();
			}catch(e){
				console.error("Failed to update table");
			}finally{
				this.tableUpdatePending = false;
			}
		}, 400);
	}
	async updateTable(): Promise<void>{
		this.persistField(this.LocalForagePersistFieldKeys);
		return await this.loadUsers();
	}

	clearLocalForage(): void{
		this.clearPersistedFields();
	}

	usersLoading: boolean = false;
	async loadUsers(): Promise<void>{
		this.usersLoading = true;
		try{
			const { query, options } = this.getQuery();
			await usersAdminStore.loadUserList({ query, options });
		}catch(e){
			console.error(e);
		}finally{
			this.usersLoading = false;
		}
	}

	private getQuery(){
		const query: RepositoryQuery<UserAccountModel> = {
			search: this.search,
			fields: ['name', 'user_metadata.referral'],
			$match:{
				roles: {
					$any: this.selectedRoles,
				},
			}
		};
		const options: QueryOptions = { 
			page: this.tableOptions.page,
			limitPerPage: this.tableOptions.itemsPerPage,
		};
		if(this.tableOptions.sortBy.length > 0){
			options.sort = {
				fields: this.tableOptions.sortBy.map((field, index) => {
					return {
						field: field,
						desc: this.tableOptions.sortDesc[index],
					};
				}),
			};
		}
		return { query, options };
	}

	syncLoading: boolean = false;
	async forceSync(): Promise<void>{
		this.syncLoading = true;
		try{
			await userAccountApi.syncUsers();
			this.loadUsers();
		}catch(e){
			console.error(e);
		}finally{
			this.syncLoading = false;
		}
	}
	userSyncLoading: boolean = false;
	async syncUserById(userId: string): Promise<void>{
		this.userSyncLoading = true;
		try{
			await userAccountApi.syncUserById(userId);
			this.loadUsers();
		}catch(e){
			console.error(e);
		}finally{
			this.userSyncLoading = false;
		}
	}
	downloadLoading: boolean = false;
	async downloadCSV(): Promise<void>{
		this.downloadLoading = true;
		try{
			const { query, options } = this.getQuery();
			delete options.limitPerPage;
			const result: PaginatedResponse<UserAccountModel & { referral: string }> = (await userAccountApi.queryAll(query, options)) as any;
			result.docs = result.docs.map(doc => {
				doc.referral = doc.user_metadata?.referral ?? "";
				return doc;
			});
			this.downloadFile(new Blob([
				this.queryResultToCSV(result, '\t'),
			], { type: 'text/csv' }));
		}catch(e){
			console.error(e);
		}finally{
			this.downloadLoading = false;
		}
	}

	private downloadFile(blob: Blob): void{
		let dl = document.createElement('a');
		dl.href = window.URL.createObjectURL(blob);
		dl.download = 'ba-users-export.csv';
		dl.click();
		document.removeChild(dl);
	}
	private queryResultToCSV(result: PaginatedResponse<any>, delim: string = ","): string{
		const [doc] = result.docs;
		const headers = Object.keys(doc);
		return [
			headers.join(delim),
			...result.docs.map(doc => headers.reduce((row, key, i) => {
				const value = doc[key];
				if(typeof value === 'object'){
					return `${i > 0 ? `${row}${delim}` : ''}${JSON.stringify(value)}`;
				}
				return `${i > 0 ? `${row}${delim}` : ''}${value}`;
			}, ""))
		].join('\n')
	}
}
