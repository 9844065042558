import store from '../store';
import { Module, VuexModule, Mutation, Action, } from 'vuex-module-decorators';
import { userAccountApi } from '@/api/UserAccountApi';
import { QueryOptions, PaginatedResponse, RepositoryQuery } from '../../../types/interfaces';
import { UserAccountModel } from '@/models/user/UserAccountModel';

const Mutations = {
	GET_USERS: 'GET_USERS',
	GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
	GET_USERS_FAILURE: 'GET_USERS_FAILURE',
}

const name = 'UsersAdminStore';

if (store.state[name]) {
	store.unregisterModule(name)
}
@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class UsersAdminModule extends VuexModule {

	loading: boolean = false;
	initialized: boolean = true;

	get UserListReady(): boolean {
		return this.initialized && !this.loading;
	}

	totalUsers: number = 0;
	userList: UserAccountModel[] = [];

	@Action({
		rawError: true,
	}) async loadUserList({ query, options } : {
		query?: RepositoryQuery<UserAccountModel>, options?: QueryOptions
	} = {}): Promise<void> {
		this.context.commit(Mutations.GET_USERS);
		try {
			const response = await userAccountApi.queryAll(
				query,
				{
					...options,
				}
			);
			this.context.commit(Mutations.GET_USERS_SUCCESS, response);
		} catch (e) {
			console.error("Failed to load users list", e);
			this.context.commit(Mutations.GET_USERS_FAILURE, e);
		}
	}

	@Mutation [Mutations.GET_USERS](): void {
		this.loading = true;
	}
	@Mutation [Mutations.GET_USERS_SUCCESS](users: PaginatedResponse<UserAccountModel>): void {
		this.loading = false;
		this.initialized = true;
		this.totalUsers = users.total;
		this.userList = users.docs;
	}
	@Mutation [Mutations.GET_USERS_FAILURE](error: any): void {
		this.loading = false;
	}

}
