/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import store from '../store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { OrganizationModel } from '@/models/organization/OrganizationModel';
import { organizationApi } from '@/api/OrganizationApi';
import { QueryOptions, PaginatedResponse, RepositoryQuery, OrganizationCreateOptions } from '@/../types/interfaces';
import { AthleteAssessmentDataModel, SpqDataModel } from '@/models';
import { athleteAssessmentDataApi } from '@/api/AthleteAssessmentDataApi';
import { spqDataApi } from '@/api/SpqDataApi';

const Mutations = {
	QUERY_ORGANIZATIONS: 'QUERY_ORGANIZATIONS',
	QUERY_ORGANIZATIONS_SUCCESS: 'QUERY_ORGANIZATIONS_SUCCESS',
	QUERY_ORGANIZATIONS_FAILURE: 'QUERY_ORGANIZATIONS_FAILURE',
	CREATE_ORGANIZATION: 'CREATE_ORGANIZATION',
	CREATE_ORGANIZATION_SUCCESS: 'CREATE_ORGANIZATION_SUCCESS',
	CREATE_ORGANIZATION_FAILURE: 'CREATE_ORGANIZATION_FAILURE',
	PATCH_ORGANIZATION: 'PATCH_ORGANIZATION',
	PATCH_ORGANIZATION_SUCCESS: 'PATCH_ORGANIZATION_SUCCESS',
	PATCH_ORGANIZATION_FAILURE: 'PATCH_ORGANIZATION_FAILURE',

	QUERY_VERIFIED_ASSESSMENTS: 'QUERY_VERIFIED_ASSESSMENTS',
	QUERY_VERIFIED_ASSESSMENTS_SUCCESS: 'QUERY_VERIFIED_ASSESSMENTS_SUCCESS',
	QUERY_VERIFIED_ASSESSMENTS_FAILURE: 'QUERY_VERIFIED_ASSESSMENTS_FAILURE',

	LOAD_SPQ_DATA_LIST: 'LOAD_SPQ_DATA_LIST',
	LOAD_SPQ_DATA_LIST_SUCCESS: 'LOAD_SPQ_DATA_LIST_SUCCESS',
	LOAD_SPQ_DATA_LIST_FAILURE: 'LOAD_SPQ_DATA_LIST_FAILURE',
}

const name = 'AdminStore';

if (store.state[name]) {
	store.unregisterModule(name)
}

@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class AdminModule extends VuexModule {

	organizations: OrganizationModel[] = [];

	createOrgLoading: boolean = false;
	@Action
	async createOrg({ organization, options }: { organization: OrganizationModel, options: OrganizationCreateOptions }): Promise<OrganizationModel>{
		this.context.commit(Mutations.CREATE_ORGANIZATION)
		try{
			const org = await organizationApi.insert(organization, { params: options });
			this.context.commit(Mutations.CREATE_ORGANIZATION_SUCCESS);
			return org;
		}catch(e){
			this.context.commit(Mutations.CREATE_ORGANIZATION_FAILURE, e);
		}
	}
	@Mutation [Mutations.CREATE_ORGANIZATION](){
		this.createOrgLoading = true;
	}
	@Mutation [Mutations.CREATE_ORGANIZATION_SUCCESS](){
		this.createOrgLoading = false;
	}
	@Mutation [Mutations.CREATE_ORGANIZATION_FAILURE](){
		this.createOrgLoading = false;
	}

	patchOrgLoading: boolean = false;
	@Action
	async patchOrg({ organization, options }: { organization: OrganizationModel, options: OrganizationCreateOptions }): Promise<OrganizationModel>{
		this.context.commit(Mutations.PATCH_ORGANIZATION)
		try{
			const org = await organizationApi.patch(organization, { params: options });
			this.context.commit(Mutations.PATCH_ORGANIZATION_SUCCESS);
			return org;
		}catch(e){
			this.context.commit(Mutations.PATCH_ORGANIZATION_FAILURE, e);
		}
	}
	@Mutation [Mutations.PATCH_ORGANIZATION](){
		this.patchOrgLoading = true;
	}
	@Mutation [Mutations.PATCH_ORGANIZATION_SUCCESS](){
		this.patchOrgLoading = false;
	}
	@Mutation [Mutations.PATCH_ORGANIZATION_FAILURE](){
		this.patchOrgLoading = false;
	}

	/* TEAMS */
	numActiveTeams: number = 0;
	
	/* ORGANIZATIONS */
	organizationsLoading: boolean = false;
	organizationsInitialized: boolean = false;
	activeOrganizations: number = 0;
	/** Total Organizations in current query */
	totalOrganizations: number = 0;
	organizationList: OrganizationModel[] = [];
	@Action({
		rawError: true,
	}) async loadOrganizationList({ query, options }: {
		query?: RepositoryQuery<OrganizationModel>, options?: QueryOptions
	} = {}): Promise<void> {
		this.context.commit(Mutations.QUERY_ORGANIZATIONS);
		try {
			const [{ count }, orgs] = await Promise.all([
				organizationApi.countAll(),
				organizationApi.queryAll(
					{
						fields: ['name'],
						...query
					}, options,
				),
			]);
			this.context.commit(Mutations.QUERY_ORGANIZATIONS_SUCCESS, { count, orgs });
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.QUERY_ORGANIZATIONS_FAILURE, e);
		}
	}

	@Mutation [Mutations.QUERY_ORGANIZATIONS](): void {
		this.organizationsLoading = true;
	}
	@Mutation [Mutations.QUERY_ORGANIZATIONS_SUCCESS]({count,orgs}: {count: number, orgs: PaginatedResponse<OrganizationModel>}): void {
		this.organizationsLoading = false;
		this.organizationsInitialized = true;
		this.activeOrganizations = count;
		this.totalOrganizations = orgs.total;
		this.organizationList = orgs.docs;
	}
	@Mutation [Mutations.QUERY_ORGANIZATIONS_FAILURE](error: any): void {
		this.organizationsLoading = false;
	}


	verifiedAssessmentsLoading: boolean = false;
	verifiedAssessmentsInitialized: boolean = false;
	totalVerifiedAssessments: number = 0;
	verifiedAssessmentList: AthleteAssessmentDataModel[] = [];
	@Action({
		rawError: true,
	}) async loadVerifiedAssessmentList({ query, options }: {
		query?: RepositoryQuery<AthleteAssessmentDataModel>, options?: QueryOptions
	} = {}): Promise<void> {
		this.context.commit(Mutations.QUERY_VERIFIED_ASSESSMENTS);
		try {
			const [{ count }, data] = await Promise.all([
				athleteAssessmentDataApi.countAll(),
				athleteAssessmentDataApi.queryAll(
					{
						fields: [],
						...query
					}, options,
				),
			]);
			this.context.commit(Mutations.QUERY_VERIFIED_ASSESSMENTS_SUCCESS, { count, data });
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.QUERY_VERIFIED_ASSESSMENTS_FAILURE, e);
		}
	}

	@Mutation [Mutations.QUERY_VERIFIED_ASSESSMENTS](): void {
		this.verifiedAssessmentsLoading = true;
	}
	@Mutation [Mutations.QUERY_VERIFIED_ASSESSMENTS_SUCCESS]({data}: {data: PaginatedResponse<AthleteAssessmentDataModel>}): void {
		this.verifiedAssessmentsLoading = false;
		this.verifiedAssessmentsInitialized = true;
		this.totalVerifiedAssessments = data.total;
		this.verifiedAssessmentList = data.docs;
	}
	@Mutation [Mutations.QUERY_VERIFIED_ASSESSMENTS_FAILURE](error: any): void {
		this.verifiedAssessmentsLoading = false;
	}

	spqDataLoading: boolean = false;
	totalSpqData: number = 0;
	spqDataList: SpqDataModel[] = [];

	@Action({
		rawError: true,
	}) async loadSpqDataList({ query, options }: {query?: RepositoryQuery<SpqDataModel>, options?: QueryOptions} = {}): Promise<void> {
		this.context.commit(Mutations.LOAD_SPQ_DATA_LIST);
		try {
			const count = await spqDataApi.countAll();
			console.log(count);
			const data = await spqDataApi.queryAll(
				{
					fields: [],
					...query
				}, options,
			);

			this.context.commit(Mutations.LOAD_SPQ_DATA_LIST_SUCCESS, { count, data });
		} catch (e) {
			console.error("Failed to load spq data list", e);
			this.context.commit(Mutations.LOAD_SPQ_DATA_LIST_FAILURE, e);
		}
	}

	@Mutation [Mutations.LOAD_SPQ_DATA_LIST](): void {
		this.spqDataLoading = true;
	}
	@Mutation [Mutations.LOAD_SPQ_DATA_LIST_SUCCESS]({data}: {data: PaginatedResponse<SpqDataModel>}): void {
		this.spqDataLoading = false;
		this.totalSpqData = data.total;
		this.spqDataList = data.docs;
	}
	@Mutation [Mutations.LOAD_SPQ_DATA_LIST_FAILURE](error: any): void {
		this.spqDataLoading = false;
	}
}
