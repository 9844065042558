
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { mdiMagnify, mdiChevronDown, mdiClose, mdiDownload, mdiInformationOutline } from '@mdi/js';
import { DebounceMixin, LocalForageMixin, VuetifyMixin } from '@/mixins';
import { ContactInfo } from '@/../types/interfaces';
import CSVUploadDialog from '@/components/teams/CSVUploadDialog.vue';
import ExcelDataTable from '@/components/ui/ExcelDataTable.vue';
import Dialog from '@/components/ui/Dialog.vue';
import AdminAutoCompleteInput from '@/components/forms/AdminAutoCompleteInput.vue';
import { PageState } from '@/models/PageState';
import { AthleteAssessmentDataModel } from '@/models';
import { formatDatePretty, formatDateHyphensYYYYMMDD } from '@/helpers';
import { TeamGenderValues } from '@/../types/enums';
import { capitalize } from '@/pipes';
import { AssessmentEntry } from 'types/interfaces/PyhsicalAssessmentDataFormValue';
import { OrganizationModel } from '@/models/organization/OrganizationModel';
import { TeamModel } from '@/models/team';
import { OrganizationMemberImportData } from 'types/interfaces/OrganizationMemberImportData';
import { mdiUpload } from "@mdi/js";

const IMPORT_COLUMNS_MAP = {
	"organizationId": true,
	"teamId": true,
	"athleteShortId": true,
	"assessmentDate": true,
	"firstName": true,
	"lastName": true,
	"email": true,
	"playingLevel": true,
	"competitiveLevel": true,
	"gender": true,
	"dominantFoot": true,
	"playingPosition": true,
	"dateOfBirth": true,
	"ageYear": true,
	"ageMonthRemainder": true,
	"ageMonthDecimal": true,
	"age": true,
	"mass": true,
	"standingHeight": true,
	"trueSittingHeight": true,
	"sittingHeightWithBox": true,
	"boxHeight": true,
	"legLength": true,
	"heightAgain": true,
	"massAgain": true,
	"sittingHeightAgain": true,
	"legTrunk": true,
	"ageLeg": true,
	"ageSittingHeight": true,
	"ageMass": true,
	"massHeightRatio": true,
	"bodyMassIndex": true,
	"maturityOffset": true,
	"ageOfPeakHeightVelocity": true,
	"developmentalCategory": true,
	"counterMovementJumpHeight": true,
	"power": true,
	"dropJumpContactTime": true,
	"dropJumpHeight": true,
	"reactiveStrengthIndex": true,
	"tenMeterSprint": true,
	"acceleration": true,
	"twentyMeterSprint": true,
	"thirtyFiveMeterSprint": true,
	"twentyToThirtyFiveMeterSplit": true,
	"speed": true,
	"yoyoIntermittentRecoveryTestStage": true,
	"yoyoIntermittentRecoveryTestDistance": true,
	"maximalAerobicVelocity": true,
};
const IMPORT_COLUMNS = Object.keys(IMPORT_COLUMNS_MAP);

export type NameToIdMap = { organizations: Record<string,string>, teams: Record<string,string> };

@Component({
	components: {
		AdminAutoCompleteInput,
		CSVUploadDialog,
		Dialog,
		ExcelDataTable,
	}
})
export default class VerifiedAssessmentImportV3 extends Mixins(DebounceMixin, LocalForageMixin, VuetifyMixin){
	mdiDownload = mdiDownload;
	mdiInformationOutline = mdiInformationOutline;
	mdiMagnify = mdiMagnify;
	mdiChevronDown = mdiChevronDown;
	mdiClose = mdiClose;
  mdiUpload = mdiUpload;
	formatDatePretty = formatDatePretty;
	capitalize = capitalize;
	TeamGenderValues = TeamGenderValues;
	showInfo: boolean = false;

	pageState = new PageState("Ready");

	nameToIdMap: NameToIdMap = { organizations: {}, teams: {} };
	notify = false;
	fixDuplicates = false;
	ignoreLinks = false;

	get OrgNames(): string[]{
		const names = new Set<string>();
		this.unconfirmedAssessments
			.filter(a => !!a.link.organizationId)
			.forEach(a => names.add(a.link.organizationId));
		return Array.from(names);
	}
	get OrgNamesCount(): number{
		return this.OrgNames.length;
	}
	get TeamNames(): string[]{
		const names = new Set<string>();
		this.unconfirmedAssessments
			.filter(a => !!a.link.teamIds)
			.map(a => a.link.teamIds)
			.reduce((a,b) => [...a, ...b], [])
			.filter(a => !!a)
			.forEach(teamName => names.add(teamName));
		return Array.from(names);
	}
	get TeamNamesCount(): number{
		return this.TeamNames.length
	}

	onOrgSelected(name: string, org: OrganizationModel): void{
		Vue.set(this.nameToIdMap.organizations, name, org.id);
	}
	clearOrg(name: string): void{
		Vue.set(this.nameToIdMap.organizations, name, undefined);
	}
	onTeamSelected(name: string, team: TeamModel): void{
		Vue.set(this.nameToIdMap.teams, name, team.id);
	}
	clearTeam(name: string): void{
		Vue.set(this.nameToIdMap.teams, name, undefined);
	}

	CSVDataTableHeaders = [
		{ text: "", sortable: false, value: "row", align: "end", width: "60px" },
		...IMPORT_COLUMNS.map(col => ({
			text: col,
			sortable: false,
			value: col,
		})),
	];
	sampleCSVData = [
		{
			organizationId: "League One",
			teamId: "U16 Boys",
			athleteShortId: "1A2B3C",
			assessmentDate: "08/23/2020",
			firstName: "Abigail",
			lastName: "Armstrong",
			email: "abby101@yten.nl",
			playingLevel: "U14",
			competitiveLevel: "2",
			gender: "female",
			dominantFoot: "left",
			playingPosition: "Midfielder",
			dateOfBirth: "02/01/2006",
			...IMPORT_COLUMNS.slice(10).map(x => ({[x]: "#"})),
		},
		{
			organizationId: "League One",
			teamId: "U16 Boys",
			athleteShortId: "4D5E6F",
			assessmentDate: "08/23/2020",
			firstName: "Brian",
			lastName: "Armstrong",
			email: "brian101@yten.nl",
			playingLevel: "U15",
			competitiveLevel: "3",
			gender: "male",
			dominantFoot: "right",
			playingPosition: "Striker",
			dateOfBirth: "25/12/2002",
			...IMPORT_COLUMNS.slice(10).map(x => ({[x]: "#"})),
		},
	];
	csvParseFn = (csvString: string): AssessmentEntry[] => {
		const rows = csvString.split('\n')
			.map(line => line.replaceAll('\r', '')) // Remove CR characher if using CRLF lines
			.filter(line => line.length > 0)
			.map(line => line.split(',')).slice(1);
		const keys = this.CSVDataTableHeaders.slice(1).map(h => h.value.trim());
		const rowJson: Record<keyof typeof IMPORT_COLUMNS_MAP, string>[] = rows.map(row => {
			return keys.reduce<Record<keyof typeof IMPORT_COLUMNS_MAP, string>>((json, value, index) => {
				if(row[index] === undefined || row[index] === '#DIV/0!'){
					// Missing or invalid value in cell
					json[value] = undefined
				}else if(["email","dateOfBirth","assessmentDate"].includes(value)){
					// For emails, only trim whitespace
					json[value] = row[index].trim();
				}else{
					// For all other values, remove any non-alphanumeric chars '" "10"' => 10
					json[value] = row[index].replace(/[^A-Za-z0-9-.]/g, '').trim();
				}
				return json;
			}, {} as any);
		});
		const assessments = rowJson.map(row => {
			const contact: ContactInfo = {
				firstName: row.firstName,
				lastName: row.lastName,
				email: row.email,
			};
			const link: AssessmentEntry['link'] = {
				organizationId: row.organizationId,
				teamIds: [row.teamId],
				athleteShortId: row.athleteShortId ?? "",
			};
			const data: AthleteAssessmentDataModel = {
				...row as any,
			};
			return { contact, data, link };
		});
		return assessments;
	};

	CSVUploadDialogVisible = false;
	CSVUploadPreviewModalVisible = false;

	unconfirmedAssessments: Array<AssessmentEntry> = [];
	get AssessmentRowPreview(): Array<any>{
		return this.unconfirmedAssessments.map(x => ({
			...x.link,
			...x.contact,
			...x.data,
		}));
	}
	/**
	 * Returns Org Member import data which can be uploaded on step 3 of the organization create page to automatically generate teams
	 */
	private getOrgMemberImportData({ orgName = null, teamName = null }: {orgName?: string | null, teamName?: string | null}): OrganizationMemberImportData[]{
		const assessmentEntryToOrgMember = (a: AssessmentEntry): OrganizationMemberImportData => ({
			firstName: a.contact.firstName,
			lastName: a.contact.lastName,
			birthDate: a.data.dateOfBirth,
			team: a.link.teamIds[0],
			gender: a.data.gender,
			role: "Player",
			email: a.contact.email,
		});
		if(teamName !== null){
			return this.unconfirmedAssessments
				.filter(a => a.link.teamIds.includes(teamName))
				.map(assessmentEntryToOrgMember);
		}else if(orgName !== null){
			return this.unconfirmedAssessments
				.filter(a => a.link.organizationId.includes(orgName))
				.map(assessmentEntryToOrgMember);
		}else{
			return this.unconfirmedAssessments.map(assessmentEntryToOrgMember);
		}
	}
	downloadOrgMemberImportData({ orgName = null, teamName = null }: {orgName?: string | null, teamName?: string | null}): void{
		const csv = this.getOrgMemberImportData({ orgName, teamName })
			.filter(row => row)
			.map(row => {
				const r = [];
				r.push(row.firstName);
				r.push(row.lastName);
				r.push(row.birthDate);
				r.push(row.team);
				r.push(row.gender);
				r.push(row.role);
				r.push(row.email);
				return r.join(',');
			}).join('\n');
		const blob = new Blob([csv]);
		let dl = document.createElement('a');
		dl.href = window.URL.createObjectURL(blob);
		dl.download = `assessment-organization-member-import-${teamName ? teamName : orgName ? orgName : `${formatDateHyphensYYYYMMDD(new Date)}`}.csv`;
		dl.click();
	}
	loadCSVData(data: Array<AssessmentEntry>): void {
		this.unconfirmedAssessments = data;
		this.CSVUploadPreviewModalVisible = true
	}
	startOver(): void{
		this.CSVUploadPreviewModalVisible = false;
		this.unconfirmedAssessments = [];
	}
	confirmCSVData(): void {
		this.$emit('submit', {
			assessments: this.useLinkedIds(this.unconfirmedAssessments),
			notify: this.notify,
			fixDuplicates: this.fixDuplicates
		});
		this.startOver();
	}
	get AssessmentCount(): number{
		return this.unconfirmedAssessments.length;
	}
	get EmailCount(): number{
		return this.unconfirmedAssessments.filter(a => !!a.contact.email).length;
	}
	useLinkedIds(assessments: AssessmentEntry[]): AssessmentEntry[]{
		return assessments.map(a => {
			const { organizationId, teamIds } = a.link;
			const [teamId] = teamIds ?? [];
			if(organizationId && this.nameToIdMap.organizations[organizationId]){
				a.data.organizationId = this.nameToIdMap.organizations[organizationId];
				a.link.organizationId = this.nameToIdMap.organizations[organizationId];
			}else{
				a.data.organizationId = undefined;
				a.link.organizationId = undefined;
			}
			if(teamId && this.nameToIdMap.teams[teamId]){
				a.link.teamIds = [this.nameToIdMap.teams[teamId]];
			}else{
				a.link.teamIds = undefined;
			}
			return a;
		});
	}

}
