
import { Component, Mixins } from 'vue-property-decorator';
import AdminSchoolSearchModule from '@/store/admin/AdminSchoolSearch.store';
import { getModule } from 'vuex-module-decorators'
const adminSchoolSearchStore = getModule(AdminSchoolSearchModule);
import { DataOptions, DataTableHeader } from 'vuetify';
import { mdiMagnify, mdiEye, mdiDelete, mdiCogSyncOutline } from '@mdi/js';
import { DebounceMixin, LocalForageMixin, VuetifyMixin } from '@/mixins';
import Page from '@/views/Page.vue';
import * as AdminRoutes from '@/../types/constants/admin.routes';
import { QueryOptions, RepositoryQuery, ContextMenuItem } from '@/../types/interfaces';
import { SchoolModel } from '@/models/school/SchoolModel';
import SortIcon from '@/components/ui/SortIcon.vue';
import { schoolApi } from '@/api/SchoolApi';
import { notificationStore } from '@/store';
import { NotificationModel } from '@/models/notification/NotificationModel';
import { NotificationUIType } from '@/../types/enums';
import { UploadDialog, UploadResponse } from "@/components/utilities/upload/UploadDialog.vue";
import { ConfirmationDialog } from "@/components/ui/ConfirmationDialog.vue";

const DEFAULT_OPTIONS = {
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	sortDesc: [true],
	groupBy: [],
	groupDesc: [false],
	multiSort: false,
	mustSort: false,
};

@Component({
	components: {
		Page,
		SortIcon,
		UploadDialog,
		ConfirmationDialog
	}
})
export default class SchoolDashboard extends Mixins(DebounceMixin, LocalForageMixin, VuetifyMixin){
	mdiMagnify = mdiMagnify;
	mdiDelete = mdiDelete;
	mdiCogSyncOutline = mdiCogSyncOutline;

	useMongoDB: boolean = false
	
	schoolImportFile: string = '';
	uploadDialogVisible: boolean = false;
	importingSchools: boolean = false;
	defaultUploadNames = ['schools-final-v3(sports list).json.gz', 'niche_cleaned.json.gz', 'canadianschools.json.gz']

	createSchool(): void{
		this.$router.push({
			name: AdminRoutes.SchoolCreate,
		});
	}
	editSchool(id: string): void{
		this.$router.push({
			name: AdminRoutes.SchoolEdit,
			params:{
				editSchoolId: id,
			}
		});
	}
	async uploadSchools(res: UploadResponse): Promise<void> {
		await schoolApi.importSchoolFile(res.key);
	}

	viewSchool(id: string): void{
		this.editSchool(id);
	}

	async deleteSchool(school: SchoolModel): Promise<void> {
		await schoolApi.delete(school)
		this.debounceUpdateTable();
	}

	async createIndex(): Promise<void>{
		await schoolApi.createIndex();
		notificationStore.pushLocalNotification(new NotificationModel().load({
			uiType: NotificationUIType.Banner,
			message: 'School Index is being rebuilt'
		}))
	}

	search: string = "";
	tableOptions: DataOptions = DEFAULT_OPTIONS;
	get TableSortBy(): string{
		const [value] = this.tableOptions.sortBy;
		return value;
	}
	get TableSortDesc(): boolean{
		const [value] = this.tableOptions.sortDesc;
		return value;
	}
	localForagePersistFields: Array<string | [string, any]> = [
		// ['tableOptions', DEFAULT_OPTIONS], 
		['search', ""],
	];
	headers: DataTableHeader<any>[] = [
		{
			text: 'Name',
			value: 'name',
			sortable: true,
		},
		{
			text: 'Country',
			value: 'country',
			sortable: true,
		},
		{
			text: 'City',
			value: 'city',
			sortable: true,
		},
		{
			text: '',
			value: 'data-table-controls'
		},
	];
	get PageLoading(): boolean{
		return !this.SchoolListReady;
	}
	get TableLoading(): boolean{
		return adminSchoolSearchStore.schoolsLoading;
	}
	get SchoolListReady(): boolean{
		return adminSchoolSearchStore.schoolsInitialized && !adminSchoolSearchStore.schoolsLoading;
	}
	get ActiveSchools(): number{
		return adminSchoolSearchStore.activeSchools;
	}
	get TotalSchools(): number{
		return adminSchoolSearchStore.totalSchools;
	}
	get TotalPages(): number{
		return Math.ceil(adminSchoolSearchStore.totalSchools / this.tableOptions.itemsPerPage);
	}
	get SchoolList(): SchoolModel[]{
		return adminSchoolSearchStore.schoolList;
	}
	mounted(): void{
		this.debounceUpdateTable();
	}
	tableUpdatePending: boolean = false;
	debounceUpdateTable(): void{
		this.tableUpdatePending = true;
		this.debounceCallback('updateTable', async () => {
			try{
				await this.updateTable();
			}catch(e){
				console.error("Failed to update table");
			}finally{
				this.tableUpdatePending = false;
			}
		}, 400);
	}
	async updateTable(): Promise<void>{
		this.persistField(this.LocalForagePersistFieldKeys);
		return await this.loadSchools();
	}
	clearLocalForage(): void{
		this.clearPersistedFields();
	}

	schoolsLoading: boolean = false;
	async loadSchools(): Promise<void>{
		this.schoolsLoading = true;
		try{
			const query: RepositoryQuery<SchoolModel> = {
				$or: [
					{
						$nestedPath: 'locations',
						$search: {
							query: `${this.search}`,
							fields: ['locations.nameSearch'],
							type: "phrase",
							boost: 10,
						}
					},
					{
						$nestedPath: 'locations',
						$search: {
							query: `${this.search}`,
							fields: ['locations.nameSearch'],
							type: "phrase_prefix",
							boost: 3,
						}
					},
					{
						$nestedPath: 'locations',
						$search: {
							query: `*${this.search}*`,
							fields: ['locations.nameSearch'],
							type: "best_fields",
							boost: 2,
						}
					},
				]
			};
			const options: QueryOptions = { 
				page: this.tableOptions.page,
				limitPerPage: this.tableOptions.itemsPerPage,
			};
			if(this.tableOptions.sortBy.length > 0){
				options.sort = {
					fields: this.tableOptions.sortBy.map((field, index) => {
						return {
							field: field,
							desc: this.tableOptions.sortDesc[index],
						};
					}),
				};
			}
			await adminSchoolSearchStore.loadSchoolList({ query, options, useMongoDB: this.useMongoDB });
		}catch(e){
			console.error(e);
		}finally{
			this.schoolsLoading = false;
		}
	}

	menuItems(item: SchoolModel): ContextMenuItem[]{
		return [
			{
				text: 'View',
				icon: mdiEye,
				click: () => {
					this.viewSchool(item.id);
				}
			},
			{
				text: 'Delete',
				icon: mdiDelete,
				click: () => {
					this.deleteSchool(item);
				}
			},
		]
	}

	async importSchoolFromFile(): Promise<void> {
		this.importingSchools = true;
		await schoolApi.importSchoolFile(this.schoolImportFile)
		this.importingSchools = false;
	}
}
