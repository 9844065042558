
import { Component, Prop, Mixins } from 'vue-property-decorator';

import { mdiPlus, mdiCloseCircle, mdiMapMarkerOutline, mdiChevronDown } from '@mdi/js';
import { SportName } from '@/../types/enums';
import FormAddArea from '../forms/FormAddArea.vue';
import FileUploadArea from '../forms/FileUploadArea.vue';
import MonthPicker from '../forms/MonthPicker.vue';
import { OrganizationTypeSelectorMixin, SportsSelectorMixin, CountriesSelectorMixin } from '../../mixins/SelectorMixins';

import { mdiCameraPlusOutline } from '@mdi/js';
import { FormRulesMixin } from '@/mixins';

@Component({
	components: { FormAddArea, FileUploadArea, MonthPicker },
})
export default class OrganizationCreateOrgInfo extends Mixins(CountriesSelectorMixin, OrganizationTypeSelectorMixin, SportsSelectorMixin, FormRulesMixin){
	mdiPlus = mdiPlus;
	mdiMapMarkerOutline = mdiMapMarkerOutline;
	mdiChevronDown = mdiChevronDown;
	mdiCloseCircle = mdiCloseCircle;
	mdiCameraPlusOutline = mdiCameraPlusOutline;

	showLinkOrgDialog: boolean = false;

	thisYear: number = new Date().getFullYear();
	@Prop() value = {
		valid: false,
		name: "",
		type: "",
		country: "",
		city: "",
		sport: SportName.Soccer,
		league: "",
		logoUrl: null,
	};
	input(): void{
		this.$emit('input', this.value);
	}

	$refs:{
		form: HTMLFormElement;
	}

	async submit($event: Event): Promise<void> {
		$event.preventDefault();
		this.$refs.form.validate();
		if(this.value.valid === false) return;
		this.$emit('submit', this.value);
	}
}
