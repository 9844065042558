
import { Component, Mixins } from 'vue-property-decorator';
import { mdiMagnify } from '@mdi/js';
import { DebounceMixin, LocalForageMixin, VuetifyMixin } from '@/mixins';
import { ContactInfo } from '@/../types/interfaces';
import CSVUploadDialog from '@/components/teams/CSVUploadDialog.vue';
import ExcelDataTable from '@/components/ui/ExcelDataTable.vue';
import { PageState } from '@/models/PageState';
import { AthleteAssessmentDataModel } from '@/models';
import { formatDatePretty } from '@/helpers';
import { TeamGenderValues } from '@/../types/enums';
import { capitalize } from '@/pipes';
import { AssessmentEntry } from 'types/interfaces/PyhsicalAssessmentDataFormValue';

const IMPORT_COLUMNS_MAP = {
	"assessmentDate": true,
	"firstName": true,
	"lastName": true,
	"email": true,
	"playingLevel": true,
	"competitiveLevel": true,
	"gender": true,
	"dominantFoot": true,
	"playingPosition": true,
	"dateOfBirth": true,
	"ageYear": true,
	"ageMonthRemainder": true,
	"ageMonthDecimal": true,
	"age": true,
	"mass": true,
	"standingHeight": true,
	"trueSittingHeight": true,
	"sittingHeightWithBox": true,
	"boxHeight": true,
	"legLength": true,
	"heightAgain": true,
	"massAgain": true,
	"sittingHeightAgain": true,
	"legTrunk": true,
	"ageLeg": true,
	"ageSittingHeight": true,
	"ageMass": true,
	"massHeightRatio": true,
	"bodyMassIndex": true,
	"maturityOffset": true,
	"ageOfPeakHeightVelocity": true,
	"developmentalCategory": true,
	"counterMovementJumpHeight": true,
	"power": true,
	"dropJumpContactTime": true,
	"dropJumpHeight": true,
	"reactiveStrengthIndex": true,
	"tenMeterSprint": true,
	"acceleration": true,
	"twentyMeterSprint": true,
	"thirtyFiveMeterSprint": true,
	"twentyToThirtyFiveMeterSplit": true,
	"speed": true,
	"yoyoIntermittentRecoveryTestStage": true,
	"yoyoIntermittentRecoveryTestDistance": true,
	"maximalAerobicVelocity": true,
};
const IMPORT_COLUMNS = Object.keys(IMPORT_COLUMNS_MAP);

@Component({
	components: {
		CSVUploadDialog,
		ExcelDataTable,
	}
})
export default class VerifiedAssessmentImport extends Mixins(DebounceMixin, LocalForageMixin, VuetifyMixin){
	mdiMagnify = mdiMagnify;
	formatDatePretty = formatDatePretty;
	capitalize = capitalize;
	TeamGenderValues = TeamGenderValues;

	pageState = new PageState("Ready");

	CSVDataTableHeaders = [
		{ text: "", sortable: false, value: "row", align: "end", width: "60px" },
		...IMPORT_COLUMNS.map(col => ({
			text: col,
			sortable: false,
			value: col,
		})),
	];
	sampleCSVData = [
		{
			assessmentDate: "08/23/2020",
			firstName: "Abigail",
			lastName: "Armstrong",
			email: "abby101@yten.nl",
			playingLevel: "U14",
			competitiveLevel: "2",
			gender: "female",
			dominantFoot: "left",
			playingPosition: "Midfielder",
			dateOfBirth: "02/01/2006",
			...IMPORT_COLUMNS.slice(10).map(x => ({[x]: "#"})),
		},
		{
			assessmentDate: "08/23/2020",
			firstName: "Brian",
			lastName: "Armstrong",
			email: "brian101@yten.nl",
			playingLevel: "U15",
			competitiveLevel: "3",
			gender: "male",
			dominantFoot: "right",
			playingPosition: "Striker",
			dateOfBirth: "25/12/2002",
			...IMPORT_COLUMNS.slice(10).map(x => ({[x]: "#"})),
		},
	];
	csvParseFn = (csvString: string): AssessmentEntry[] => {
		const rows = csvString.split('\n')
		.map(line => line.replaceAll('\r', '')) // Remove CR characher if using CRLF lines
		.map(line => line.split(',')).slice(1);
		const keys = this.CSVDataTableHeaders.slice(1).map(h => h.value.trim());
		const rowJson: Record<keyof typeof IMPORT_COLUMNS_MAP, string>[] = rows.map(row => {
			return keys.reduce<Record<keyof typeof IMPORT_COLUMNS_MAP, string>>((json, value, index) => {
				json[value] = row[index];
				return json;
			}, {} as any);
		});
		const assessments = rowJson.map(row => {
			const contact: ContactInfo = {
				firstName: row.firstName,
				lastName: row.lastName,
				email: row.email,
			};
			const data: AthleteAssessmentDataModel = {
				...row as any,
			};
			// TODO: allow automatic linking to athlete profiles
			return { contact, data, link: null };
		});
		return assessments;
	};

	CSVUploadDialogVisible = false;
	CSVUploadPreviewModalVisible = false;

	unconfirmedAssessments: Array<AssessmentEntry> = [];
	get AssessmentRowPreview(): Array<any>{
		return this.unconfirmedAssessments.map(x => ({
			...x.contact,
			...x.data,
		}));
	}
	loadCSVData(data: Array<AssessmentEntry>): void {
		this.unconfirmedAssessments = data;
		this.CSVUploadPreviewModalVisible = true
	}
	startOver(): void{
		this.CSVUploadPreviewModalVisible = false;
		this.unconfirmedAssessments = [];
	}
	confirmCSVData(): void {
		this.$emit('submit', this.unconfirmedAssessments);
		this.startOver();
	}
	get AssessmentCount(): number{
		return this.unconfirmedAssessments.length;
	}
	get EmailCount(): number{
		return this.unconfirmedAssessments.filter(a => !!a.contact.email).length;
	}

}
