import { api } from './api'

export async function allFlags() {
	return api(c => c.get('/featureFlag/allFlags'))
}

export async function findById(id) {
	return api(c => c.get('/featureFlag/' + id));
}

/**
 * Create or Update feature flag.
 * @param {key, value} obj 
 */
export async function update(obj) {
	return api(c => c.put('/featureFlag', obj))
}

export async function deleteById(id) {
	return api(c => c.delete('/featureFlag/' + id))
}


export default {
	allFlags,
	findById,
	update,
	deleteById,
}