import { AppMetadata, UserExportData, UserMetadata } from '@/../types/interfaces';
import { RoleName } from '@best-athletes/ba-types';
import { FrontEndModel } from '../FrontEndModel';

export class UserAccountModel extends FrontEndModel implements UserExportData {
	user_id: string;
	email: string;
	name: string;
	family_name: string;
	given_name: string;
	phone_number?: string;
	nickname?: string;
	username?: string;
	last_ip?: string;
	logins_count?: number;
	created_at?: Date;
	updated_at?: Date;
	deleted_at?: Date;
	last_login?: Date;
	blocked?: boolean;
	deleted?: boolean;
	email_verified?: boolean;
	phone_verified?: boolean;
	user_metadata?: UserMetadata;
	app_metadata?: AppMetadata;

	roles!: RoleName[];
	athleteProfileId: string | null = null;
	childProfileIds: string[] | null = null;
	coachProfileId: string | null = null;

	load(obj: Record<string, any>): this {
		Object.assign(this, obj);
		if(obj['created_at']) this.created_at = new Date(obj['created_at']);
		if(obj['updated_at']) this.updated_at = new Date(obj['updated_at']);
		if(obj['deleted_at']) this.deleted_at = new Date(obj['deleted_at']);
		if(obj['last_login']) this.last_login = new Date(obj['last_login']);
		return this;
	}
}