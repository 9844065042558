
import { Component, Mixins, Watch, PropSync, Prop } from 'vue-property-decorator';
import { mdiMagnify } from '@mdi/js';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import { AthleteProfileModel } from '@/models/athlete/AthleteProfileModel';
import { athleteApi } from '@/api/AthleteApi';
import { DebounceMixin } from '@/mixins';

@Component
export default class AdminAthleteAutocomplete extends Mixins(DebounceMixin, VuetifyMixin){
	mdiMagnify = mdiMagnify;
	search: string = "";

	@PropSync('value') Value: AthleteProfileModel | null;
	@Prop({ default: "Athletes" }) label: string;
	@Prop({ default: "Start typing to search" }) placeholder: string;
	@Prop({ default: 8 }) limit: number;
	athleteItems: AthleteProfileModel[] = [];

	searchLoading: boolean = false;
	@Watch('search') debounceSearchAthletes(): void{
		this.searchLoading = true;
		this.debounceCallback('searchAthletes', this.searchAthletes.bind(this), 150);
	}
	
	async searchAthletes(): Promise<void>{
		this.searchLoading = true;
		try{
			const athleteSearch = await athleteApi.queryAll({
				search: this.search,
				fields: [
					'firstName',
					'lastName',
					'email',
				]
			},{
				limitPerPage: this.limit,
			});
			this.athleteItems = athleteSearch.docs;
		}catch(e){
			console.error('Failed to query athletes', e);
		}finally{
			this.searchLoading = false;
		}
	}
}
