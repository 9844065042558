
import { Component, Vue } from 'vue-property-decorator';
import { mdiSend } from '@mdi/js';
import { CrudApi } from '../../api/CrudApi';
import Page from '../Page.vue';
import { RESOURCES } from '@/../types/constants/resources';

@Component({
	components:{
		Page,
	}
})
export default class SchemaUpgrade extends Vue{
	mdiSend = mdiSend;
	version: string = ""
	selectedResource: string = ""
	RESOURCES: string[] = RESOURCES;

	get Ready(): boolean{
		return !!this.selectedResource && !!this.version;
	}
	get Response(): string{
		return this.response;
	}
	get UpgradeURL(): string{
		return `${this.selectedResource}/schema-upgrade/${this.version}`;
	}
	response: string | null = null;

	loading: boolean = false;
	async submit(): Promise<void>{
		this.response = null;
		this.loading = true;
		try{
			const res = await CrudApi.Api(c => c.post(this.UpgradeURL));
			this.response = JSON.stringify(res, null, 2);
		}catch(e){
			console.error(e);
		}finally{
			this.loading = false;
		}
	}
}
