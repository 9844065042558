export const ConferenceLogoMapping = {
	null: '',
	'NESCAC': '',
	'A-10': '',
	'Mid-American (MAC)': '',
	'Hockey East': '',
	'Colonial': '',
	'New England Collegiate': '',
	'MASCAC': '',
	'Great Northeast': '',
	'Massachusetts CC Athletic Conf': '',
	'NEWMAC': '',
	'Liberty League': '',
	'Independent': '',
	'Little East': '',
	'Empire 8': '',
	'ECAC': '',
	'NEWA': '',
	'Northeast 10': '',
	'Atlantic Hockey': '',
	'NIRA (Rugby)': '',
	'Commonwealth Coast': '',
	'Eastern Intercollegiate Ski Association': '',
	'Colonial Hockey Conference': '',
	'Patriot League': '',
	"US Rowing Collegiate Championships Div.-Men's Sprints": '',
	'Eastern Collegiate Football Conf.': '',
	'Northeast': '',
	'America East': '',
	'New England Hockey Conference': '',
	'Ivy League': '',
	'ECAC Hockey': '',
	'EIVA': '',
	'Collegiate Water Polo Assn': '',
	'EIWA': '',
	'North Atlantic': '',
	'New England Intercollegiate Fencing Conference': '',
	'Mid Atlantic Rifle': '',
	'United Volleyball': '',
	'ACC': '',
	'University Athletic Association': '',
	'Region 21': '',
	'Mid-Atlantic Rowing': '',
	'Metro Atlantic (MAAC)': '',
	'Big East': '',
	"New England Women's Hockey Alliance": '',
	'Yankee': '',
	'EAGL': '',
	"Northeast Women's Golf Conf.": '',
	'North Eastern': '',
	'Northeastern Intercollegiate Athletics Conference': '',
	'USCAA': '',
	'Big Sky': '',
	'Metropolitan Collegiate Swimming': '',
	"Nat'l Collegiate Acrobatics & Tumbling Assn.": '',
	'East Coast': '',
	'Central Atlantic': '',
	'Collegiate Sprint FB': '',
	'Garden State': '',
	'MAC Freedom': '',
	'Centennial': '',
	'NJAC': '',
	'Colonial States': '',
	'Skyline': '',
	'CVC': '',
	'Atlantic Sun': '',
	'Allegheny Mountain': '',
	'Capital': '',
	'Atlantic East Conference': '',
	'Southland': '',
	'Big South': '',
	'Landmark': '',
	'Big Ten': '',
	'Region 15': '',
	'CUNYAC': '',
	'Northeast FB': '',
	'United Collegiate Hockey Conference': '',
	'Mid-Hudson': '',
	'GARC': '',
	'Missouri Valley': '',
	'Mountain Valley': '',
	'Hudson Valley': '',
	'Region 3': '',
	'SUNYAC': '',
	'Pioneer': '',
	"Northeast Women's Hockey League": '',
	'Mid-State': '',
	'Empire Wrestling Conference': '',
	'College Hockey America': '',
	'Sun Belt': '',
	'Middle Atlantic Sailing': '',
	"Presidents'": '',
	'WPCC': '',
	'PSUAC': '',
	'Horizon League': '',
	'River States': '',
	'PSAC': '',
	'Great Midwest Athletic Conference': '',
	'North Coast': '',
	'Mountain Pacific Sports Fed': '',
	'MAC Commonwealth': '',
	'EPAC': '',
	'American': '',
	'Central Intercollegiate': '',
	'Region 19': '',
	'Mid-Eastern (MEAC)': '',
	'Maryland JUCO': '',
	'Mountain East': '',
	'Old Dominion': '',
	'Southern': '',
	'Conference USA': '',
	'Big 12': '',
	'Coastal Collegiate Sports Association': '',
	'Appalachian': '',
	'Mid-South': '',
	"NAIA Men's VB": '',
	'South Atlantic': '',
	'USA South': '',
	'Region 10': '',
	'NCCAA': '',
	'Conference Carolinas': '',
	'Sun Coast Swimming Conference': '',
	'Blue Grass Mountain Swim Conf': '',
	'Peach Belt': '',
	'Assoc. Independent Institutions': '',
	'SIAC': '',
	'SEC': '',
	'Gulf South': '',
	'Southern Athletic Association': '',
	'Georgia Collegiate Athletic Assn.': '',
	'Southern States': '',
	'Sun Conf.': '',
	'FCSAA Mid-Florida': '',
	'Sunshine State': '',
	'Gulf Coast': '',
	'FCSAA Panhandle': '',
	'NSISC': '',
	'FCSAA Southern': '',
	'FCSAA Suncoast': '',
	'Region 8': '',
	'Alabama CC Conf': '',
	'SWAC': '',
	'WCHA': '',
	'Ohio Valley': '',
	'TCCAA': '',
	'MACJC': '',
	'American Southwest': '',
	'NIRA (Rodeo)': '',
	'SLIAC': '',
	'Heartland Collegiate': '',
	'Crossroads League': '',
	'Ohio Athletic': '',
	'Midwest Fencing Conf.': '',
	'Patriot Rifle': '',
	'MIVA': '',
	'Ohio CC Athletic Conf': '',
	'CCHA': '',
	'Midwest Collegiate Volleyball League': '',
	'Missouri Valley FB': '',
	'GLIAC': '',
	'Ohio Regional Campus Conf': '',
	'National Collegiate Hockey Conference': '',
	'Region 12': '',
	'Mid-States Football Assn': '',
	'American Midwest': '',
	'Great Lakes Valley': '',
	'Summit League': '',
	'Michigan CC Athletic Assn': '',
	'Wolverine-Hoosier': '',
	'Kansas Collegiate': '',
	'Michigan Intercollegiate': '',
	'Chicagoland': '',
	'Northern Collegiate Hockey Assoc': '',
	'Sooner': '',
	'Region 24': '',
	'Region 13': '',
	'Iowa CC Athletic Conf': '',
	'Midwest': '',
	'American Rivers Conference': '',
	'Heart of America': '',
	'North Star Athletic Association': '',
	"Midwest Women's Lacrosse Conf.": '',
	'Great Plains': '',
	'CCIW': '',
	'Northern Sun': '',
	'Mid-America Intercollegiate': '',
	'Midwest Lacrosse': '',
	'Upper Midwest': '',
	'Northern Athletics Collegiate Conf.': '',
	'Assoc. of DIII Independents': '',
	'WIAC': '',
	'N4C': '',
	'MCAC': '',
	'Minnesota Intercollegiate': '',
	'Rocky Mountain': '',
	'Mon-Dak': '',
	'Great Northwest': '',
	'Frontier': '',
	'Region 9': '',
	'Rocky Mtn. Ski Assn.': '',
	'Illinois Skyway': '',
	'Region 4': '',
	'WAC': '',
	'MWAC': '',
	'Midwest Independent Gymnastics Conf.': '',
	'Great Rivers': '',
	'Western Water Polo': '',
	'Missouri CC Athletic Conf': '',
	'Region 16': '',
	'Kansas Jayhawk CC Conf': '',
	'West Coast': '',
	'Nebraska CCAC': '',
	'Region 23': '',
	'Region 14': '',
	'Red River': '',
	'Southern Collegiate': '',
	'GAC': '',
	'Pac-12': '',
	'Region 2': '',
	'Lone Star': '',
	'Oklahoma Collegiate': '',
	'Southwest JC Football Conf': '',
	'Region 5': '',
	'WJCAC': '',
	'Pacific West': '',
	'Mountain West': '',
	'Scenic West': '',
	'Cascade': '',
	'United States Collegiate Ski & Snowboard Assn.': '',
	'Mountain Rim': '',
	'NWAC': '',
	'Region 18': '',
	'Arizona CC Athletic Conf': '',
	'Golden State': '',
	'PCSC': '',
	'California Collegiate': '',
	'SCIAC': '',
	'Golden Coast': '',
	'South Coast': '',
	'SCFA': '',
	'Western State': '',
	'PCAC': '',
	'Orange Empire': '',
	'Big West': '',
	'Inland Empire': '',
	'California Pacific': '',
	'Central Valley': '',
	'NCFC': '',
	'Bay Valley': '',
	'Big 8': '',
	'Coast': '',
	'Golden Valley': '',
	'Northwest': '',
	'Northern Intercollegiate Athletic Conference': '',
	'Region 6': '',
	'Region 20': '',
	'Region 22': '',
	'RSEQ': '',
	'OUA': '',
	'AUS': '',
	'CW': '',
	'CCAA': '',
}

export const Conferences = Object.keys(ConferenceLogoMapping);