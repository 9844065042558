/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import store from '../store';
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { SchoolModel } from '@/models/school/SchoolModel';
import { schoolApi } from '@/api/SchoolApi';
import { QueryOptions, PaginatedResponse, RepositoryQuery } from '@/../types/interfaces';

const Mutations = {
	QUERY_SCHOOLS: 'QUERY_SCHOOLS',
	QUERY_SCHOOLS_SUCCESS: 'QUERY_SCHOOLS_SUCCESS',
	QUERY_SCHOOLS_FAILURE: 'QUERY_SCHOOLS_FAILURE',
	CREATE_SCHOOL: 'CREATE_SCHOOL',
	CREATE_SCHOOL_SUCCESS: 'CREATE_SCHOOL_SUCCESS',
	CREATE_SCHOOL_FAILURE: 'CREATE_SCHOOL_FAILURE',
	PATCH_SCHOOL: 'PATCH_SCHOOL',
	PATCH_SCHOOL_SUCCESS: 'PATCH_SCHOOL_SUCCESS',
	PATCH_SCHOOL_FAILURE: 'PATCH_SCHOOL_FAILURE',
}

const name = 'AdminStore';

if (store.state[name]) {
	store.unregisterModule(name)
}

@Module({
	namespaced: true,
	dynamic: true,
	name,
	store: store
})
export default class AdminModule extends VuexModule {

	schools: SchoolModel[] = [];

	saveSchoolLoading: boolean = false;
	@Action
	async saveSchool({ school }: { school: SchoolModel }): Promise<SchoolModel>{
		this.context.commit(Mutations.PATCH_SCHOOL)
		try{
			const newSchool = await schoolApi.save(school);
			this.context.commit(Mutations.PATCH_SCHOOL_SUCCESS);
			return newSchool;
		}catch(e){
			this.context.commit(Mutations.PATCH_SCHOOL_FAILURE, e);
		}
	}
	@Mutation [Mutations.PATCH_SCHOOL](){
		this.saveSchoolLoading = true;
	}
	@Mutation [Mutations.PATCH_SCHOOL_SUCCESS](){
		this.saveSchoolLoading = false;
	}
	@Mutation [Mutations.PATCH_SCHOOL_FAILURE](){
		this.saveSchoolLoading = false;
	}

	schoolsLoading: boolean = false;
	schoolsInitialized: boolean = false;
	activeSchools: number = 0;
	/** Total Schools in current query */
	totalSchools: number = 0;
	schoolList: SchoolModel[] = [];
	@Action({
		rawError: true,
	}) async loadSchoolList({ query, options, useMongoDB = false }: {
		query?: RepositoryQuery<SchoolModel>, options?: QueryOptions, useMongoDB?: boolean
	} = {}): Promise<void> {
		this.context.commit(Mutations.QUERY_SCHOOLS);
		try {
			const finalQuery = {
				...query
			};
			const [{ count }, schools] = await Promise.all([
				schoolApi.countAll(),
				useMongoDB ? schoolApi.queryAll(finalQuery, options) : schoolApi.searchSchools({ query: finalQuery, options}),
			]);
			this.context.commit(Mutations.QUERY_SCHOOLS_SUCCESS, { count, schools });
		} catch (e) {
			console.error("Failed to load events list", e);
			this.context.commit(Mutations.QUERY_SCHOOLS_FAILURE, e);
		}
	}

	@Mutation [Mutations.QUERY_SCHOOLS](): void {
		this.schoolsLoading = true;
	}
	@Mutation [Mutations.QUERY_SCHOOLS_SUCCESS]({count,schools}: {count: number, schools: PaginatedResponse<SchoolModel>}): void {
		this.schoolsLoading = false;
		this.schoolsInitialized = true;
		this.activeSchools = count;
		this.totalSchools = schools.total;
		this.schoolList = schools.docs;
	}
	@Mutation [Mutations.QUERY_SCHOOLS_FAILURE](error: any): void {
		this.schoolsLoading = false;
	}
}
