
import { Component, Vue } from 'vue-property-decorator';
import { mdiSoccer, mdiSchool, mdiSitemap, mdiCalendarCheck, mdiAccount, mdiHeadCog, mdiRun } from '@mdi/js';
import Page from '../views/Page.vue';
import * as AdminRoutes from '../../types/constants/admin.routes';
import { Route } from 'vue-router';
import AdminCard from '../components/admin/AdminCard.vue';

@Component({
	components: {
		AdminCard,
		Page,
	}
})
export default class AdminDashboard extends Vue{
	iconUser = mdiAccount;
	iconOrgChart = mdiSitemap;
	iconTeam = mdiSoccer;
	iconSchool = mdiSchool;
	iconCalendar = mdiCalendarCheck;
	iconPhysical = mdiRun;
	iconPersonality = mdiHeadCog;

	goToUserAdmin(): void{
		this.$router.push({name: AdminRoutes.UserAdminDashboard});
	}

	goToTeamsAdmin(): void{
		this.$router.push({name: AdminRoutes.TeamAdminDashboard});
	}

	goToOrgsAdmin(): void{
		this.$router.push({name: AdminRoutes.OrganizationAdminDashboard});
	}

	goToEvents(): void{
		this.$router.push({name: AdminRoutes.EventAdmin});
	}

	goToSchoolSearch(): void{
		this.$router.push({name: AdminRoutes.SchoolSearchAdminDashboard});
	}

	goToSPQUpload(): void{
		this.$router.push({name: AdminRoutes.SPQUploadAdminDashboard});
	}

	goToPhysicalAssessments(): void {
		this.$router.push({name: AdminRoutes.VerifiedAssessmentAdminDashboard});
	}
}
