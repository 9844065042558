
import { Component, Prop, Mixins } from 'vue-property-decorator';

import { mdiChevronDown, mdiPlus } from '@mdi/js';
import FormAddArea from '@/components/forms/FormAddArea.vue';
import FileUploadArea from '@/components/forms/FileUploadArea.vue';
import { SocialMediaInput } from "@/components/forms/SocialMediaInput.vue";
import { FormRulesMixin } from '@/mixins';
import { SportNameValues, SportGender, SportName } from "@/../types/enums";
import { SchoolModel, CoachDetailModel, SportDetailModel } from '@/models/school/SchoolModel';
import { Conferences } from '@/../types/constants/conferences';

@Component({
	components: { FormAddArea, FileUploadArea, SocialMediaInput },
})
export default class SchoolCreateSchoolTeam extends Mixins(FormRulesMixin){
	mdiChevronDown = mdiChevronDown;
	mdiPlus = mdiPlus;
	SportNameValues = SportNameValues;

	Conferences = Conferences

	@Prop({ required: true }) sportName: string;

	@Prop({ required: true}) value: SchoolModel;
	input(): void{
		this.$emit('input', this.value);
	}
	@Prop({ required: true }) locationIndex: number;
	@Prop({ required: true }) sportIndex: number;

	@Prop({ default: true }) valid: boolean;
	inputValid(): void{
		this.$emit('update:valid', this.valid);
	}

	$refs:{
		form: HTMLFormElement;
	}

	async submit($event: Event): Promise<void> {
		$event.preventDefault();
		this.$refs.form.validate();
		if(this.valid === false) return;
		this.$emit('submit', this.value);
	}

	async addStaffMember(): Promise<void> {
		console.log('Adding new staff')
		this.value.locations[this.locationIndex].sports[this.sportIndex].coaches.push(new CoachDetailModel())
	}
	async deleteStaffMember(index: number): Promise<void> {
		this.value.locations[this.locationIndex].sports[this.sportIndex].coaches.splice(index, 1)
	}

	get SportNames(): string[]{
		return this.SportNameValues;
	}

	get Sport(): SportDetailModel {
		return this.value.locations[this.locationIndex].sports[this.sportIndex];
	}
	set Sport(detail: SportDetailModel )  {
		this.value.locations[this.locationIndex].sports[this.sportIndex] = detail;
	}

	get SportName(): SportName {
		return this.Sport.name;
	}
	set SportName(newSportName: SportName) {
		this.Sport.name = newSportName;
	}

	get Gender(): SportGender {
		return this.Sport.gender;
	}
	set Gender(sportGender: SportGender) {
		this.Sport.gender = sportGender;
	}

	get Conference(): string {
		return this.Sport.conference
	}
	set Conference(newConference: string) {
		this.Sport.conference = newConference
	}

	get Division(): string {
		return this.Sport.division
	}
	set Division(division: string) {
		this.Sport.division = division
	}

	get Facility(): string {
		return this.Sport.facility
	}
	set Facility(facility: string) {
		this.Sport.facility = facility
	}

}
