
import { Component, Prop, Mixins } from 'vue-property-decorator';

import { mdiPlus, mdiCloseCircle, mdiMapMarkerOutline, mdiChevronDown, mdiCityVariantOutline, mdiWeb } from '@mdi/js';
import FormAddArea from '@/components/forms/FormAddArea.vue';
import FileUploadArea from '@/components/forms/FileUploadArea.vue';

import { mdiCameraPlusOutline } from '@mdi/js';
import { FormRulesMixin, CountriesSelectorMixin, AvailableCurrencies, AppHostnameMixin } from '@/mixins';
import { SchoolModel } from '@/models/school/SchoolModel';

import { SocialMediaInput } from "@/components/forms/SocialMediaInput.vue";

@Component({
	components: { FormAddArea, FileUploadArea, SocialMediaInput },
})
export default class SchoolCreateSchoolInfo extends Mixins(AvailableCurrencies, CountriesSelectorMixin, FormRulesMixin, AppHostnameMixin){
	mdiWeb = mdiWeb;
	mdiPlus = mdiPlus;
	mdiMapMarkerOutline = mdiMapMarkerOutline;
	mdiChevronDown = mdiChevronDown;
	mdiCloseCircle = mdiCloseCircle;
	mdiCameraPlusOutline = mdiCameraPlusOutline;
	mdiCityVariantOutline = mdiCityVariantOutline;
	

	@Prop({ default: true }) valid: boolean;
	inputValid(): void{
		this.$emit('update:valid', this.valid);
	}
	@Prop({ required: true}) value: SchoolModel;
	input(): void{
		this.$emit('input', this.value);
	}

	get Hide() : boolean {
		return !this.value.hide;
	}
	set Hide(v : boolean) {
		this.value.hide = !v;
	}

	changeIds: boolean = false;
	get UnlockIds(): boolean{
		return this.changeIds;
	}

	$refs:{
		form: HTMLFormElement;
	}

	async submit($event: Event): Promise<void> {
		$event.preventDefault();
		this.$refs.form.validate();
		if(this.valid === false) return;
		this.$emit('submit', this.value);
	}
}
