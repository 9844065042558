
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { mdiMagnify } from '@mdi/js';
import { VuetifyMixin } from '../../mixins/VuetifyMixin';
import { FrontEndModel } from '@/models/FrontEndModel';
import { DebounceMixin } from '@/mixins/DebounceMixin';
import { CrudApi } from '@/api/CrudApi';
@Component
export default class AdminAutoCompleteInput<T extends FrontEndModel = FrontEndModel> extends Mixins(DebounceMixin, VuetifyMixin){
	mdiMagnify = mdiMagnify;
	loading: boolean = false;
	@Prop({ default: () => (["id", "name"]) }) searchFields: string[];
	@Prop({ default: 8 }) limitPerPage: number;
	@Prop({ default: "id" }) itemText: string;
	@Prop({ default: undefined }) itemValue: string | undefined;
	@Prop({ default: true, type: Boolean }) returnObject: boolean;
	@Prop({ default: false, type: Boolean }) clearOnSelect: boolean;
	@Prop({ default: null }) itemsFilter: (item: FrontEndModel) => boolean = null;
	@Prop({ required: true }) value: FrontEndModel;
	@Prop({ required: true }) resource: string;
	input(value: FrontEndModel): void{
		this.$emit('input', value);
	}

	onChange(): void{
		if(this.clearOnSelect){
			this.$nextTick(() => {
				this.search = '';
				this.value = null;
			});
		}
	}

	get Api(): CrudApi<T>{
		return new CrudApi<T>(this.resource, (obj) => obj as T);
	}

	search: string = "";
	items: FrontEndModel[] = [];
	get SearchItems(): FrontEndModel[]{
		if(this.itemsFilter === null) return this.items;
		return this.items.filter(this.itemsFilter);
	}

	@Watch('search') debounceSearch(): void{
		this.loading = true;
		this.debounceCallback('execSearch', this.execSearch.bind(this), 150);
	}
	
	async execSearch(): Promise<void>{
		this.loading = true;
		try{
			const res = await this.Api.queryAll({
				search: this.search,
				fields: this.searchFields
			},{
				limitPerPage: this.limitPerPage,
			});
			this.items = res.docs;
		}catch(e){
			console.error(`Failed to query ${this.resource}`, e);
		}finally{
			this.loading = false;
		}
	}

}
