
import { Component, Prop, Mixins } from 'vue-property-decorator';

import { mdiCurrencyUsd } from '@mdi/js';
import FormAddArea from '@/components/forms/FormAddArea.vue';
import FileUploadArea from '@/components/forms/FileUploadArea.vue';
import { FormRulesMixin, AvailableCurrencies } from '@/mixins';
import { SchoolModel } from '@/models/school/SchoolModel';

@Component({
	components: { FormAddArea, FileUploadArea },
})
export default class SchoolCreateSchoolCosts extends Mixins(AvailableCurrencies, FormRulesMixin){
	mdiCurrencyUsd = mdiCurrencyUsd;

	@Prop({ default: true }) valid: boolean;
	inputValid(): void{
		this.$emit('update:valid', this.valid);
	}
	@Prop({ required: true}) value: SchoolModel;
	input(): void{
		this.$emit('input', this.value);
	}

	$refs:{
		form: HTMLFormElement;
	}

	async submit($event: Event): Promise<void> {
		$event.preventDefault();
		this.$refs.form.validate();
		if(this.valid === false) return;
		this.$emit('submit', this.value);
	}
}
