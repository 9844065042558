
import { Component, Prop, Mixins, Watch, Vue } from 'vue-property-decorator';
import { mapState, mapActions } from 'vuex';
import { mdiChevronLeft } from '@mdi/js';

import Page from '../Page.vue';
import Alert from '../../components/ui/Alert.vue';
import SurveySvg from '../../components/svg/SurveySvg.vue';
import MultiStepForm from '../../components/forms/MultiStepForm.vue'; 
import { MultiStepFormMixin, VuetifyMixin } from '../../mixins';
import PhysicalAssessmentCSV from '../../components/dataEntry/PhysicalAssessmentCSV.vue';
import { CoachProfileModel } from '../../models/coach/CoachProfileModel';
import { StaffMember } from '../../models/team/StaffMember';
import { StaffRole, CourseStaffRole, CourseType } from '../../../types/enums';
import { PhysicalAssessmentDataCreateFormValue } from '../../../types/interfaces/PyhsicalAssessmentDataFormValue';


import { assessmentCreateStore } from '../../store';
import { courseApi } from '../../api/CourseApi';

@Component({
	components: {
		Alert,
		MultiStepForm,
		Page,
		SurveySvg,
		PhysicalAssessmentCSV,
	},
})
export default class Assessment extends Mixins(MultiStepFormMixin, VuetifyMixin){
	mdiChevronLeft = mdiChevronLeft;

	newCourseId: string = "";
	formComplete: boolean = false;
	get Loading(){
		return assessmentCreateStore.createAssessmentLoading
	}
	steps = 1;
	currentStep = 1;

	// get CurrentStepValid() {
	// 	const step = this.physicalAssessmentCreateDataForm[this.CurrentStepKey];
	// 	return step ? step.valid : true;
	// }

	resetForm(){
		this.physicalAssessmentCreateDataForm = this.getEmptyForm();
		this.currentStep = 1;
	}
	getEmptyForm = () => ({
		step1: {
			eventData: []
		}
	});
	physicalAssessmentCreateDataForm: PhysicalAssessmentDataCreateFormValue = this.getEmptyForm();

	finish(){
		// at this point, we really just need to make the api call

		assessmentCreateStore.createAssessments(this.physicalAssessmentCreateDataForm.step1.eventData).then();
	}
	cancel(){
		this.goToDashboard();
	}

	goToDashboard(){
		this.$router.push('/');
	}
}
