
import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import FileUploadArea from "@/components/forms/FileUploadArea.vue";
import ConfirmationDialog from "@/components/ui/ConfirmationDialog.vue";

export interface UploadResponse{
	key: string,
	url: string,
	file: File,
}

@Component({
	components: {
		FileUploadArea,
		ConfirmationDialog
	}
})
export class UploadDialog extends Mixins(VuetifyMixin){
	@PropSync('visible', { type: Boolean }) DialogVisible!: boolean;
	@PropSync('url', { type: String }) UploadUrl!: string;
	@Prop({ type: String, default: '/'}) prefix: string;
	@Prop({ type: String, default: ''}) title: string;
	@Prop({ default: ['image']}) allowedMIMETypes: string[];


	uploaded(res: UploadResponse): void {
		this.$emit('uploaded', res)
	}
}
export default UploadDialog;
