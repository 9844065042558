
import { Component, Prop, Mixins } from 'vue-property-decorator';
import CircledAvatar from '../ui/CircledAvatar.vue';
import { TeamModel } from '../../models/team';
import { TeamDetailsMixin } from '@/mixins/TeamDetailsMixin';

@Component({
	components: { CircledAvatar },
})
export default class TeamMiniCard extends Mixins(TeamDetailsMixin){

	@Prop({ required: true }) team: TeamModel;
	@Prop({ default: false, type: Boolean }) hideName: boolean;
	@Prop({ default: false, type: Boolean }) hideLocation: boolean;
	@Prop({ default: false, type: Boolean }) hideDetails: boolean;
	/** v-text-field class to align with other inputs */
	@Prop({ default: true, type: Boolean }) useInputClass: boolean;
	@Prop({ default: 'align-start', type: String }) alignClass: string;

	get TeamInitials(): string{
		return this.team.name.split(' ').map(c => c.charAt(0).toLocaleUpperCase()).join('');
	}
}
