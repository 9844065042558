
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { DataTableHeader, DataOptions } from 'vuetify';
import { mdiChevronRight, mdiCheckCircleOutline, mdiCircleOutline } from '@mdi/js';
import * as AdminRoutes from '../../../types/constants/admin.routes';
import { DataTableSortMixin, VuetifyMixin } from '../../mixins';
import DatePreview from '../ui/DatePreview.vue';
import SortIcon from '../ui/SortIcon.vue';
import { getShortMonthName, getDate, formatDatePretty } from '../../helpers/date';
import { title } from '../../pipes';
import { UserAccountModel } from '@/models/user/UserAccountModel';

const DEFAULT_OPTIONS = {
	page: 0,
	itemsPerPage: 5,
	sortBy: [],
	sortDesc: [false],
	groupBy: [],
	groupDesc: [false],
	multiSort: false,
	mustSort: false,
}

@Component({
	components: { DatePreview, SortIcon },
})
export default class BestAthletesUserList extends Mixins(DataTableSortMixin, VuetifyMixin){
	mdiChevronRight = mdiChevronRight;
	mdiCheckCircleOutline = mdiCheckCircleOutline;
	mdiCircleOutline = mdiCircleOutline;
	getDate = getDate;
	getShortMonthName = getShortMonthName;
	formatDatePretty = formatDatePretty;
	title = title;

	@Prop({ type: Boolean, default: false }) loading: boolean;
	@Prop() userList: UserAccountModel[];
	@Prop() search: string;
	@Prop() totalUsers: number;
	@Prop({
		default: () => DEFAULT_OPTIONS
	}) tableOptions: DataOptions;
	updateOptions(options: DataOptions): void{
		this.$emit('update:table-options', options);
	}
	updatePage(page: number): void{
		this.updateOptions({
			...this.tableOptions,
			page,
		});
	}

	openUser(user: UserAccountModel): void{
		this.$router.push({
			name: AdminRoutes.UserAdminDashboard,
			params:{
				userId: user.id
			}
		});
	}

	get Loading(): boolean{
		return this.loading;
	}

	@Prop({ required: true }) roleColor: () => string;

	headers: DataTableHeader<any>[] = [
		{
			text: 'Name',
			value: 'name',
			sortable: false,
		},
		{
			text: '(Verified) Email',
			value: 'email',
			sortable: false,
		},
		{
			text: 'Roles',
			value: 'roles',
			sortable: false,
		},
		{
			text: 'Registered',
			value: 'created_at',
			sortable: true,
		},
		{
			text: 'Last Login',
			value: 'last_login',
			sortable: true,
		},
		{
			text: 'Referral Code',
			value: 'app_metadata',
			sortable: false,
		},
	];

	get TableSortBy(): string{
		const [value] = this.tableOptions.sortBy;
		return value;
	}
	get TableSortDesc(): boolean{
		const [value] = this.tableOptions.sortDesc;
		return value;
	}

	getReferralCode(user: UserAccountModel): string | null{
		if(user.user_metadata === undefined) return null;
		return user.user_metadata.referral;
	}

}
