
import { Component, Mixins } from 'vue-property-decorator';
import AdminModule from '../../store/admin/Admin.store';
import { getModule } from 'vuex-module-decorators'
const adminStore = getModule(AdminModule);
import { DataOptions, DataTableHeader } from 'vuetify';
import { mdiMagnify, mdiPencil, mdiSync } from '@mdi/js';
import { DebounceMixin, LocalForageMixin, VuetifyMixin } from '@/mixins';
import Page from '@/views/Page.vue';
import * as AdminRoutes from '@/../types/constants/admin.routes';
import * as OrgAdminRoutes from '@/../types/constants/org-admin.routes';
import { QueryOptions, RepositoryQuery, ContextMenuItem } from '@/../types/interfaces';
import { OrganizationModel } from '@/models/organization/OrganizationModel';
import SortIcon from '@/components/ui/SortIcon.vue';
import { organizationApi } from '@/api/OrganizationApi';
import { notificationStore } from '@/store';
import { NotificationModel } from '@/models/notification/NotificationModel';
import { NotificationUIType } from '@/../types/enums';

const DEFAULT_OPTIONS = {
	page: 1,
	itemsPerPage: 10,
	sortBy: [],
	sortDesc: [true],
	groupBy: [],
	groupDesc: [false],
	multiSort: false,
	mustSort: false,
};

@Component({
	components: {
		Page,
		SortIcon,
	}
})
export default class OrganizationDashboard extends Mixins(DebounceMixin, LocalForageMixin, VuetifyMixin){
	mdiMagnify = mdiMagnify;

	createOrg(): void{
		this.$router.push({
			name: AdminRoutes.OrgCreate,
		});
	}
	editOrg(id: string): void{
		this.$router.push({
			name: AdminRoutes.OrgEdit,
			params:{
				editOrganizationId: id,
			}
		});
	}

	viewOrg(id: string): void{
		this.$router.push({
			name: OrgAdminRoutes.OrgAdminManageTeams,
			params:{
				organizationId: id,
			}
		});
	}

	async syncOrgAthletes(id: string): Promise<void>{
		await organizationApi.reIndexAthletes({ organizationId: id });
		notificationStore.pushLocalNotification(new NotificationModel().load({
			uiType: NotificationUIType.Banner,
			message: 'Organization Players queued to sync in the background'
		}))
	}

	search: string = "";
	tableOptions: DataOptions = DEFAULT_OPTIONS;
	get TableSortBy(): string{
		const [value] = this.tableOptions.sortBy;
		return value;
	}
	get TableSortDesc(): boolean{
		const [value] = this.tableOptions.sortDesc;
		return value;
	}
	localForagePersistFields: Array<string | [string, any]> = [
		// ['tableOptions', DEFAULT_OPTIONS], 
		['search', ""],
	];
	headers: DataTableHeader<any>[] = [
		{
			text: 'Name',
			value: 'name',
			sortable: false,
		},
		{
			text: 'Location',
			value: 'city',
			sortable: false,
		},
		{
			text: 'Sport',
			value: 'sport',
			sortable: false,
		},
		{
			text: '# of Teams',
			value: 'TeamCount',
			sortable: false,
		},
		{
			text: 'League',
			value: 'league',
			sortable: false,
		},
		{
			text: 'Type',
			value: 'type',
			sortable: false,
		},
		{
			text: 'Account Status',
			value: 'AccountStatus',
			sortable: false,
		},
		{
			text: '',
			value: 'data-table-controls'
		},
	];
	get PageLoading(): boolean{
		return !this.OrganizationListReady;
	}
	get TableLoading(): boolean{
		return adminStore.organizationsLoading;
	}
	get OrganizationListReady(): boolean{
		return adminStore.organizationsInitialized && !adminStore.organizationsLoading;
	}
	get ActiveOrganizations(): number{
		return adminStore.activeOrganizations;
	}
	get TotalOrganizations(): number{
		return adminStore.totalOrganizations;
	}
	get TotalPages(): number{
		return Math.ceil(adminStore.totalOrganizations / this.tableOptions.itemsPerPage);
	}
	get OrganizationList(): OrganizationModel[]{
		return adminStore.organizationList;
	}
	mounted(): void{
		this.debounceUpdateTable();
	}
	tableUpdatePending: boolean = false;
	debounceUpdateTable(): void{
		this.tableUpdatePending = true;
		this.debounceCallback('updateTable', async () => {
			try{
				await this.updateTable();
			}catch(e){
				console.error("Failed to update table");
			}finally{
				this.tableUpdatePending = false;
			}
		}, 400);
	}
	async updateTable(): Promise<void>{
		this.persistField(this.LocalForagePersistFieldKeys);
		return await this.loadOrganizations();
	}
	clearLocalForage(): void{
		this.clearPersistedFields();
	}

	organizationsLoading: boolean = false;
	async loadOrganizations(): Promise<void>{
		this.organizationsLoading = true;
		try{
			const query: RepositoryQuery<OrganizationModel> = {
				search: this.search,
				fields: ['name'],
			};
			const options: QueryOptions = { 
				page: this.tableOptions.page,
				limitPerPage: this.tableOptions.itemsPerPage,
			};
			if(this.tableOptions.sortBy.length > 0){
				options.sort = {
					fields: this.tableOptions.sortBy.map((field, index) => {
						return {
							field: field,
							desc: this.tableOptions.sortDesc[index],
						};
					}),
				};
			}
			await adminStore.loadOrganizationList({ query, options });
		}catch(e){
			console.error(e);
		}finally{
			this.organizationsLoading = false;
		}
	}

	menuItems(item: OrganizationModel): ContextMenuItem[]{
		return [
			{
				text: 'Edit',
				icon: mdiPencil,
				click: () => {
					this.editOrg(item.id);
				}
			},
			{
				text: 'Sync Athletes',
				icon: mdiSync,
				click: () => {
					this.syncOrgAthletes(item.id);
				}
			},
		]
	}
}
