import { UserAccountModel } from '@/models/user/UserAccountModel';
import { CrudApi } from './CrudApi';

class UserAccountApi extends CrudApi<UserAccountModel>{
	constructor() {
		super('user', (obj) => new UserAccountModel().load(obj));
	}

	syncUsers(): Promise<{ count: number }>{
		return CrudApi.Api(a => a.post(`${this.resource}/sync-all`));
	}

	async syncUserById(userId: string): Promise<void>{
		await CrudApi.Api(a => a.post(`${this.resource}/sync/${userId}`));
	}
}
export const userAccountApi = new UserAccountApi();