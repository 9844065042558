
import { Component, Vue } from 'vue-property-decorator';
import { title } from '../../pipes';
import { getFlagValue, setFlags } from '../../feature-flags';
import * as featureFlagApi from '../../api/featureFlag';
import Page from '../Page.vue';

@Component({
	components:{
		Page,
	}
})
export default class FeatureFlags extends Vue{
	
	created(){
		console.log("this.$store.state.featureFlags", this.$store.state.featureFlags);
	}

	prettifyFlag(flag){
		return flag.split('-').map(x => title(x)).join(' ');
	}

	async updateFlag(flag){
		const [key] = Object.keys(flag);
		setFlags(flag);
		await featureFlagApi.update({ key, value: flag[key] });
	}

	get AllFlags(): { key: string, value: boolean, label: string }[]{
		const flags = Object.keys(this.$store.state.featureFlags.flags);
		return flags.map(flag => {
			return {
				key: flag,
				value: getFlagValue(flag),
				label: this.prettifyFlag(flag),
			}
		});
	}
	
}
