import { api } from './api';
import { BackendJob } from '@/../types/interfaces/BackendJob';
import { BackendQueue } from 'types/interfaces';
import { AxiosRequestConfig } from 'axios';

class BackendJobApi{
	async getAllQueues(config?: AxiosRequestConfig): Promise<BackendQueue[]>{
		const response = await api(c => c.get('/backendjob/queue', config));
		return response;
	}
	async getJobs(queueName: string, config?: AxiosRequestConfig): Promise<BackendJob[]>{
		const response = await api(c => c.get(`/backendjob/queue/${queueName}/job`, config));
		return response;
	}
	async getJobById(queueName: string, jobId: string, config?: AxiosRequestConfig): Promise<BackendJob>{
		const response = await api(c => c.get(`/backendjob/queue/${queueName}/job/${jobId}`, config));
		return response;
	}
	async getJobCount(queueName: string, config?: AxiosRequestConfig): Promise<number>{
		const response = await api(c => c.get(`/backendjob/queue/${queueName}/job/count`, config));
		return response.count;
	}
	async removeCompletedJobs(queueName: string, config?: AxiosRequestConfig): Promise<void>{
		await api(c => c.delete(`/backendjob/queue/${queueName}/completed`, config));
	}
	async removeFailedJobs(queueName: string, config?: AxiosRequestConfig): Promise<void>{
		await api(c => c.delete(`/backendjob/queue/${queueName}/failed`, config));
	}
}
export const backendJobApi = new BackendJobApi();