
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { VuetifyMixin } from "@/mixins/VuetifyMixin";
import { FormRulesMixin } from '@/mixins';
import { mdiUpload } from "@mdi/js";
@Component({
	components: {  }
})
export default class ShareProfile extends Mixins(FormRulesMixin, VuetifyMixin) {
    mdiUpload = mdiUpload;
	@Prop({ default: false, type: Boolean }) show: boolean;
	input(show: boolean): void{ this.$emit('update:show', show); }
	$refs:{
		form: HTMLFormElement
	}
	@Watch('show')
	resetForm(newValue: boolean): void {
		// Reset validation state when showing the form
		if(newValue && this.$refs.form){
			this.$refs.form.reset();
		}
	}
	defaultForm = (): Record<string, any> => ({
		firstName: "",
		lastName: "",
		email: "",
		AC: null,
		CR: null,
		IS: null,
		PA: null,
	});
	spqForm = this.defaultForm();
	formValid: boolean = false;
	
	numberRules = [
		v => v >= 1 && v <= 10 || 'Must be between 1-10',
	];

	cancel(): void{
		this.input(false)
	}
	shareProfile(): void{
		this.$emit("submit", this.spqForm);
		this.input(false); // Close the modal
	}
}
