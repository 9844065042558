import { render, staticRenderFns } from "./SchemaUpgrade.vue?vue&type=template&id=583f8794&scoped=true&"
import script from "./SchemaUpgrade.vue?vue&type=script&lang=ts&"
export * from "./SchemaUpgrade.vue?vue&type=script&lang=ts&"
import style0 from "./SchemaUpgrade.vue?vue&type=style&index=0&id=583f8794&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "583f8794",
  null
  
)

export default component.exports